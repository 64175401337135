import { Box, InputAdornment, MenuItem, Typography } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import MicrosoftIcon from '@mui/icons-material/Microsoft'
import { StyledTextField } from '../../../styles/styledComponents/inputs/StyledTextField'
import PeopleIcon from '@mui/icons-material/People'
import { SetStateAction, useEffect, useState } from 'react'
import {
	usePostAndRetrieveDataMutation,
	usePostBetaObjectToCustomEndpointMutation,
} from '../../../services/proxyAPIData'
import { BetaObject, DataResponse } from '../../../utils/interfaces/APIModels'
import {
	IsPhoneNumber,
	emailValidation,
	isAlphanumeric,
	isPhoneNumberValidLength,
	isTenantIDSegmentsValid,
	showErrorToast,
	showSuccessToast,
	tenantIDHaveCorrectFormat,
	toAlphaString,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import { LeadSize } from '../../../utils/interfaces/DBModels'
import MyLocationIcon from '@mui/icons-material/MyLocation'
import BadgeIcon from '@mui/icons-material/Badge'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import './OCLeadForm.scss'
import { LoadingButton } from '@mui/lab'

const OCLeadForm = () => {
	// Proxy calls
	const [postAndRetrieveData] = usePostAndRetrieveDataMutation()
	const [postToCustomEndpoint] = usePostBetaObjectToCustomEndpointMutation()

	// Form Constants
	const [tenantID, setTenantID] = useState('')
	const [tenantIDError, setTenantIDError] = useState('')
	const [companyName, setCompanyName] = useState('')
	const [companySize, setCompanySize] = useState(0)
	const [leadSizeList, setLeadSizeList] = useState([] as LeadSize[])
	const [locations, setLocations] = useState('')
	const [customerName, setCustomerName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [phoneNumberError, setPhoneNumberError] = useState('')
	const [emailAddress, setEmailAddress] = useState('')
	const [emailAddressError, setEmailAddressError] = useState('')

	// Flags
	const [initialCallMade, setInitialCallMade] = useState(false)
	const [apiCallsDone, setAPICallsDone] = useState(false)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		// Make call
		if (!initialCallMade) {
			setInitialCallMade(true)
			getLeadSize()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialCallMade])

	// GET: Lead size (Company Size)
	const getLeadSize = async () => {
		// Error handling
		var isError = false

		// Get Lead Size List from DB
		await postAndRetrieveData('GetV2?Params=LeadSize.All()')
			.unwrap()
			.then(async (response) => {
				if (response) {
					// Convert to Object
					var responseObj = response as BetaObject

					// Check content and decrypt
					if (responseObj && responseObj.Content) {
						// Decrypt to Data Response
						var dataResponse = JSON.parse(
							await toAlphaString(responseObj.Content)
						) as DataResponse
						if (
							dataResponse &&
							Number(dataResponse.Count) > 0 &&
							dataResponse.Obj
						) {
							// Convert to lead size list
							var leadSizeResponse = dataResponse.Obj.LeadSizeList as LeadSize[]

							if (leadSizeResponse && leadSizeResponse.length > 0) {
								// Set List
								setLeadSizeList(leadSizeResponse)
							}
						}
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(async () => {
				if (isError) {
					// Log error to DB and inform users form is unavailable
				}
			})

		// API Calls Done
		setAPICallsDone(true)
	}

	// POST: Add cold lead to the DB
	const addNewColdLead = async () => {
		// Loading
		setLoading(true)

		// Error handling
		var isError = false

		// Cold Lead Object
		var addColdLeadRequest = {
			CompanyName: companyName,
			PartnerName: 'No_Partner',
			TenantID: tenantID,
			LeadSizeID: companySize,
			LeadTypeID: 1, // OC Lead
			Locations: locations,
			ContactInfo: {
				Name: customerName,
				PhoneNumber: phoneNumber,
				Email: emailAddress,
			},
			LeadWeightID: 1, // Default
		}

		// Encrypt Object
		var betaObject: BetaObject = {
			Content: toBetaString(JSON.stringify(addColdLeadRequest)),
		}

		// Get Lead Size List from DB
		await postToCustomEndpoint({
			url: 'AddUnconsentedLead',
			dataObject: betaObject,
		})
			.unwrap()
			.then(async () => {
				// Show success message and clear form
				showSuccessToast(
					'Your request has been submitted successfully. We will be in touch.'
				)
				clearForm()
			})
			.catch(async (error) => {
				if (error) {
					isError = true
				}
			})
			.finally(async () => {
				if (isError) {
					// Log error to DB and show error message
					showErrorToast(
						'An error occurred when submitting the form. Please try again.'
					)
				}
			})

		// Loading
		setLoading(false)
	}

	// ** Handle Functions ** //

	// Handle Tenant ID validation
	const handleTenantIDChange = (e: { target: any }) => {
		// Set value
		var value = e.target.value + ''
		setTenantID(value)

		// Validate
		if (
			!isAlphanumeric(value) ||
			!tenantIDHaveCorrectFormat(value) ||
			!isTenantIDSegmentsValid(value) ||
			value.trim().length !== 36
		) {
			// Error
			setTenantIDError(
				'Invalid Tenant ID format. Example: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
			)
		} else {
			setTenantIDError('')
		}
	}

	// Handle Company Size Dropdown Check
	const handleCompanySizeChange = (e: { target: any }) => {
		setCompanySize(e.target.value)
	}

	// Handle Phone Number validation
	const handlePhoneNumberChange = (e: { target: any }) => {
		// Set value
		var value = e.target.value + ''
		setPhoneNumber(value)

		// Validate
		if (!IsPhoneNumber(value)) {
			setPhoneNumberError(
				'Invalid Phone Number format. Please ensure you add a phone number in the correct format. Example: +442012344567'
			)
		} else if (!isPhoneNumberValidLength(value)) {
			setPhoneNumberError(
				'Invalid Phone Number length. Please note the max length allowed is 15'
			)
		} else {
			setPhoneNumberError('')
		}
	}

	// Handle Email validation
	const handleEmailAddressChange = (e: { target: any }) => {
		// Set value
		var value = e.target.value + ''
		setEmailAddress(value)

		// Validate
		if (!emailValidation(value)) {
			setEmailAddressError(
				'Invalid Email Address format. Please ensure you add a email address in the correct format. Example: email@email.com'
			)
		} else {
			setEmailAddressError('')
		}
	}

	// Disable button
	const disableButton = () => {
		// Check if values are empty or any errors
		if (
			tenantID.length === 0 ||
			tenantIDError.length > 0 ||
			companyName.length === 0 ||
			companySize === 0 ||
			locations.length === 0 ||
			customerName.length === 0 ||
			phoneNumber.length === 0 ||
			phoneNumberError.length > 0 ||
			emailAddress.length === 0 ||
			emailAddressError.length > 0
		) {
			return true
		}
	}

	// Clear form
	const clearForm = () => {
		// Reset all variables
		setTenantID('')
		setCompanyName('')
		setCompanySize(0)
		setCustomerName('')
		setLocations('')
		setPhoneNumber('')
		setEmailAddress('')
	}

	return apiCallsDone ? (
		<>
			<Box id='getInTouch' className='oc-lead-form-container'>
				{/* Grid 1 - Form Header and Icon */}
				<Box className='oc-lead-form-header' component='form'>
					{/* Icon */}
					<img
						src={require('../../../assets/contact-icon.png')}
						alt='Get In Touch'
					/>
					<Box className='text'>
						<Typography component='h2'>Get In Touch</Typography>
						<Typography component='p'>
							Please fill out all fields, submit the form <br /> and we will be
							in touch shortly
						</Typography>
					</Box>
				</Box>
				{/* Grid 2 - Form */}
				<Box className='oc-lead-form'>
					{/* Header */}
					<Typography component='h4'>Company Information</Typography>
					{/* Input - Microsoft Tenant ID */}
					<StyledTextField
						required
						label='Microsoft Tenant ID'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<MicrosoftIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={tenantID}
						onChange={handleTenantIDChange}
						helperText={tenantIDError}
						error={tenantIDError.length > 0}
					/>
					{/* Input - Company Name */}
					<StyledTextField
						required
						label='Company Name'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<BusinessIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={companyName}
						onChange={(e: { target: { value: SetStateAction<string> } }) =>
							setCompanyName(e.target.value)
						}
					/>
					{/* Input - Company Size */}
					<StyledTextField
						required
						select
						label='Company Size'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<PeopleIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={companySize}
						onChange={handleCompanySizeChange}>
						{/* Map lead size list */}
						{leadSizeList.map((item) => {
							return item.Minimum === item.Maximum ? (
								<MenuItem key={item.LeadSizeID} value={item.LeadSizeID}>
									{item.Minimum}
								</MenuItem>
							) : (
								<MenuItem key={item.LeadSizeID} value={item.LeadSizeID}>
									{item.Minimum} - {item.Maximum}
								</MenuItem>
							)
						})}
					</StyledTextField>
					{/* Input - Locations */}
					<StyledTextField
						required
						label='Locations'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<MyLocationIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={locations}
						onChange={(e: { target: { value: SetStateAction<string> } }) =>
							setLocations(e.target.value)
						}
						helperText='Example: UK'
					/>
					{/* Header */}
					<Typography component='h4'>Contact Details</Typography>
					{/* Input - Customer Name */}
					<StyledTextField
						required
						label='Full Name'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<BadgeIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={customerName}
						onChange={(e: { target: { value: SetStateAction<string> } }) =>
							setCustomerName(e.target.value)
						}
					/>
					{/* Input - Phone Number */}
					<StyledTextField
						required
						label='Phone Number'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<PhoneIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						value={phoneNumber}
						onChange={handlePhoneNumberChange}
						helperText={phoneNumberError}
						error={phoneNumberError.length > 0}
					/>
					{/* Input - Email Address */}
					<StyledTextField
						required
						label='Email Address'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<EmailIcon />
								</InputAdornment>
							),
						}}
						variant='filled'
						type='email'
						value={emailAddress}
						onChange={handleEmailAddressChange}
						helperText={emailAddressError}
						error={emailAddressError.length > 0}
					/>
					{/* Button */}
					<LoadingButton
						disabled={disableButton()}
						loading={loading}
						variant='contained'
						onClick={addNewColdLead}>
						Submit Request
					</LoadingButton>
				</Box>
			</Box>
		</>
	) : (
		<></>
	)
}

export default OCLeadForm
