import { Box, Button, Typography } from '@mui/material'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import OCLeadForm from '../components/distinct/OCLanding/OCLeadForm'
import { useEffect, useState } from 'react'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'

function OCLanding() {
	// Navigate
	const navigateToSection = () => {
		const element = document.getElementById('getInTouch')

		if (element) {
			element.scrollIntoView({ behavior: 'smooth' })
		}
	}

	// Flags
	const [hideForm, setHideForm] = useState(false)
	// Get current URL
	const currentURL = window.location.href

	useEffect(() => {
		// Check URL to see if it contains tenantID then do not show the form
		if (currentURL.includes('tenantID')) {
			// Hide Form
			setHideForm(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			<Box component='main'>
				{/* Header */}
				<Box component='header'>
					<img src={require('../assets/halo-logo.png')} alt='HALO logo' />
				</Box>
				{/* Hero Banner */}
				<Box className='hero'>
					<Box className='icon'>
						<ConnectWithoutContactIcon />
					</Box>
					<Typography component='h1'>
						Enabling your business with Operator Connect for Microsoft Teams
					</Typography>
					{!hideForm && (
						<Button variant='contained' onClick={navigateToSection}>
							Get In Touch
						</Button>
					)}
				</Box>
				{/* Content */}
				<Box className='content'>
					{/* Image */}
					<Box className='bg one' />
					{/* Text - Tile 1 */}
					<Box className='content-text'>
						<Typography component='h3'>
							Break down communication barriers and collaborate seamlessly
						</Typography>
						<Typography component='p'>
							Elevate your workforce's connectivity by enabling virtual
							collaboration across devices and locations with{' '}
							<strong>HALO</strong> Solutions for Microsoft Teams.
						</Typography>
						<Typography component='p'>
							Incorporate PSTN access seamlessly with <strong>HALO</strong>{' '}
							Operator Connect, ensuring cost-effective and smooth communication
							for your business.
						</Typography>
					</Box>
					{/* Text */}
					<Box className='content-text'>
						<Typography component='h3'>
							Unparalleled flexibility and a range of choice
						</Typography>
						<Typography component='p'>
							Seamlessly integrate PSTN calling into Microsoft Teams with
							Operator Connect, a service managed by operators, delivering a
							next-gen calling experience. Administrators can easily select and
							authorize <strong>HALO</strong> as the preferred operator by
							clicking on the <strong>HALO</strong> link in the Microsoft Teams
							Admin Centre.
						</Typography>
						<Typography component='p'>
							Enhance voice quality in Microsoft Teams by adding a high-quality
							voice network through this cloud-based solution, with no
							additional equipment or upfront investment.
						</Typography>
					</Box>
					{/* Image */}
					<Box className='bg two' />
					{/* Image */}
					<Box className='bg three' />
					{/* Text */}
					<Box className='content-text'>
						<Typography component='h3'>
							Reduced costs and simplified processes
						</Typography>
						<Typography component='p'>
							With <strong>HALO</strong> Operator Connect, achieve cost savings
							compared to a do-it-yourself approach and bypass the
							time-consuming tasks of project and infrastructure setup.
						</Typography>
						<Typography component='p'>
							Operator Connect streamlines the process of adding numbers and
							PSTN , while our Managed and Professional services offer
							comprehensive assistance from start to finish.
						</Typography>
					</Box>
				</Box>
				{/* Contact */}
				{hideForm ? (
					<Box className='contact'>
						<Box className='icon'>
							<ContactPhoneIcon />
						</Box>
						<Typography component='h2'>
							We will be in contact shortly
						</Typography>
					</Box>
				) : (
					<OCLeadForm />
				)}
				{/* Footer */}
				<Box component='footer'>
					<Typography component='p'>
						© Copyright {new Date().getFullYear()}. All rights reserved.
					</Typography>
				</Box>
			</Box>
		</>
	)
}

export default OCLanding
