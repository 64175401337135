import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// ** Declare the global state interface ** //

// Used for audit log purposes
interface OCUser {
	value: string
}

// ** Set the initial value ** //
const initialOCUserState: OCUser = {
	value: 'OCLead',
}

// ** Create the slice ** //
export const OCUserSlice = createSlice({
	name: 'ocUser',
	initialState: initialOCUserState,
	reducers: {
		setOCUser: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

// ** Set actions ** //
export const { setOCUser } = OCUserSlice.actions

// Get actions
export const getOCUser = (state: RootState) =>
	state.RootReducer.ocUserReducer.value

// ** Reducers ** //
var ocUserReducer = OCUserSlice.reducer

// ** Export the root reducer to use ** //
const rootReducer = combineReducers({
	ocUserReducer,
})

export default rootReducer
