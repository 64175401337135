import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(() => ({
	borderRadius: 0,
	color: '#000',
	fontFamily: `'Poppins', sans-serif !important`,
	'& .MuiFilledInput-root': {
		borderRadius: 0,
		color: '#5341b2',
		fontFamily: `'Poppins', sans-serif !important`,
	},
	'& input.MuiFilledInput-input': {
		color: '#000',
	},
	'& fieldset.MuiFilledInput-notchedOutline': {
		borderColor: '#5341b2',
	},
	'& .rmv fieldset.MuiFilledInput-notchedOutline': {
		borderColor: '#d32f2f',
	},
	'.MuiInputBase-root::after': {
		borderColor: '#5341b2',
	},
	'.Mui-focused': {
		color: '#5341b2!important',
		svg: {
			color: '#5341b2!important',
		},
	},
	'.MuiFormLabel-root': {
		fontFamily: `'Poppins', sans-serif !important`,
		fontWeight: '500',
	},
	'.MuiSelect-filled': {
		color: '#000',
	},
	'.MuiFormLabel-asterisk': {
		color: '#ff0000!important',
	},
	'.MuiFormHelperText-root': {
		textTransform: 'none',
		marginLeft: 0,
	},
}))
