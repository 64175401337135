import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BetaObject } from '../utils/interfaces/APIModels'

// Proxy Base URL
const baseURL = process.env.REACT_APP_PROXY_FUNCTION_BASE_URL

export const proxyAPIData = createApi({
	reducerPath: 'proxyAPIData',
	baseQuery: fetchBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			headers.set(
				'x-functions-key',
				process.env.REACT_APP_API_PROXY_FUNCTION_KEY + ''
			)
			return headers
		},
		baseUrl: baseURL,
	}),
	tagTypes: ['Data', 'Permissions', 'User'],
	refetchOnReconnect: true,

	endpoints: (build) => ({
		// GET Call - Pass in a string and return encrypted object or string
		PostAndRetrieveData: build.mutation<BetaObject | string, string>({
			query: (queryURL) => ({
				url: queryURL,
				method: 'POST',
			}),
		}),
		// POST Call - Post to custom endpoint
		PostBetaObjectToCustomEndpoint: build.mutation<
			BetaObject,
			{ url: string; dataObject: BetaObject }
		>({
			query: ({ url, dataObject }) => ({
				url,
				method: 'POST',
				body: dataObject,
			}),
		}),
	}),
})

export const {
	usePostAndRetrieveDataMutation,
	usePostBetaObjectToCustomEndpointMutation,
} = proxyAPIData
