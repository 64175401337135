import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom'
import './App.scss'
import OCLanding from './pages/OCLanding'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' element={<Navigate to='/ocr58965' />} />
					<Route path='/ocr58965' element={<OCLanding />} />
				</Routes>
			</Router>
			{
				<ToastContainer
					style={{
						width: '350px',
						paddingLeft: '10px',
					}}
					position='top-right'
					autoClose={6000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick={false}
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			}
		</>
	)
}

export default App
