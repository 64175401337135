import { toast } from 'react-toastify'
import { BetaAPIMutation, BetaObject } from '../interfaces/APIModels'

export async function toAlphaString(betaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const alphaMap = new Map<string, string>()

	for (let i = 0; i < beta.length; i++) {
		alphaMap.set(beta[i], alpha[i])
	}
	const alphaTextArray = Array.from(betaText, (betaChar) =>
		alphaMap.has(betaChar) ? alphaMap.get(betaChar)! : betaChar
	)
	const alphaText = alphaTextArray.join('')
	return alphaText
}

export function getEmptyAPIMutationObject() {
	var emptyBetaObj: BetaObject = {
		Content: '',
	}

	var emptyApiMutationObj: BetaAPIMutation = {
		BetaObject: emptyBetaObj,
		QueryParam: '',
	}

	return emptyApiMutationObj
}

export function getFormattedDate(): string {
	var today = new Date()
	var _year = today.getUTCFullYear() + ''
	var _month = today.getUTCMonth() + 1 + ''
	var _day = today.getUTCDate() + ''
	var _hours = today.getUTCHours() + ''
	var _minutes = today.getUTCMinutes() + ''
	var _seconds = today.getUTCSeconds() + ''

	if (_month.length < 2) {
		_month = '0' + _month
	}
	if (_day.length < 2) {
		_day = '0' + _day
	}
	if (_hours.length < 2) {
		_hours = '0' + _hours
	}
	if (_minutes.length < 2) {
		_minutes = '0' + _minutes
	}
	if (_seconds.length < 2) {
		_seconds = '0' + _seconds
	}

	var formattedDate = `${_year}-${_month}-${_day}T${_hours}:${_minutes}:${_seconds}`
	return formattedDate
}

export async function toBeta(obj: any) {
	var alphaText = JSON.stringify(obj)
	return toBetaString(alphaText)
}

export function toBetaString(alphaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const betaMap = new Map<string, string>()

	for (let i = 0; i < alpha.length; i++) {
		betaMap.set(alpha[i], beta[i])
	}

	let betaText = ''
	for (let i = 0; i < alphaText.length; i++) {
		const alphaChar = alphaText[i]
		betaText += betaMap.has(alphaChar) ? betaMap.get(alphaChar)! : alphaChar
	}
	return betaText
}

export function numberToChar(num: number) {
	// 👇️ for Uppercase letters, replace `a` with `A`
	const code = 'A'.charCodeAt(0)
	return String.fromCharCode(code + num)
}

export function getUTCDateTimeNow() {
	var today = new Date()
	var utcToday = new Date(
		Date.UTC(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			today.getUTCHours(),
			today.getUTCMinutes(),
			today.getUTCSeconds()
		)
	)
	return utcToday
}

export function hasGraphScopes(scopes: string[]) {
	var scopeFound = false
	for (var i = 0; i < scopes.length; i++) {
		if (scopes[i].toLowerCase().includes('.default')) {
			scopeFound = true
		}
	}
	return scopeFound
}

export function emailValidation(email: string): boolean {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return regex.test(email)
}

export function jsonValidator(jsonString: string) {
	try {
		JSON.parse(jsonString)
		return true
	} catch (e) {
		return false
	}
}

export function timeout(delay: number) {
	return new Promise((res) => setTimeout(res, delay))
}

// Used to get the property of an object based on the string we pass in
export function getProperty<T>(obj: T, key: keyof T) {
	return obj[key]
}

// Generic function to extract a specific property based on a dynamic property
export function extractPropertyByID<T, K extends keyof T, I>(
	array: T[],
	id: I,
	propertyToCompare: K,
	propertyName: K
): T[K] | undefined {
	const foundItem = array.find((item) => item[propertyToCompare] === id)
	return foundItem ? foundItem[propertyName] : undefined
}

// Display avatar icon
export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: 'primary.main',
			color: 'primary.contrastText',
			fontSize: '20px',
			width: '50px',
			height: '50px',
		},
		children: name[0],
	}
}

// Lead helpers
export function isAlphanumeric(value: string): boolean {
	return !!(value && /^[a-zA-Z0-9-]+$/.test(value))
}

export function tenantIDHaveCorrectFormat(value: string): boolean {
	return (value.match(/-/g) || []).length === 4
}

export function IsPhoneNumber(value: string): boolean {
	const phoneRegex = /^\+?\d+$/
	return phoneRegex.test(value)
}

export function isPhoneNumberValidLength(phoneNumber: string): boolean {
	const maxLength = 15
	return phoneNumber.replace(/\s/g, '').length <= maxLength
}

export function isTenantIDSegmentsValid(value: string): boolean {
	const requiredSegmentLengths = [8, 4, 4, 4, 12]
	const segments = value.split('-')
	if (segments.length !== requiredSegmentLengths.length) {
		return false
	}
	for (let i = 0; i < segments.length; i++) {
		if (segments[i].length !== requiredSegmentLengths[i]) {
			return false
		}
	}
	return true
}

// Toasts
export function showErrorToast(apiErrorMessage: string) {
	toast.error(apiErrorMessage, {
		position: 'top-right',
		autoClose: 2500,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}

export function showSuccessToast(apiErrorMessage: string) {
	toast.success(apiErrorMessage, {
		position: 'top-right',
		autoClose: 1200,
		hideProgressBar: false,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
	})
}
